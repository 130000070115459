<template>
  <div>
    <el-dialog
      title="往来初始化"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="80px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="类型" prop="billType">
              <el-radio-group v-model.number="form.billType" @change="typeChange">
                <el-radio :label="1" border>供应商</el-radio>
                <el-radio :label="2" border>客户</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商" v-if="form.billType==1" prop="companyId">
              <base-select
                info="Supplier"
                v-model="form.companyId"
                :canAdd="true"
                @addClick="()=>{this.supplierItem = {}}"
              ></base-select>
            </el-form-item>
            <el-form-item label="客户" v-if="form.billType==2" prop="companyId">
              <base-select
                info="Client"
                v-model="form.companyId"
                :canAdd="true"
                @addClick="()=>{this.clientItem = {}}"
              ></base-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="金额" prop="amount">
              <el-popover ref="amount" placement="top-start" trigger="focus">
                <span style="color: teal;">{{form.amount|money}}</span>
              </el-popover>
              <el-input
                v-popover:amount
                type="number"
                v-model="form.amount"
                placeholder="金额"
                clearable
                @mousewheel.native.prevent
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="摘要">
          <el-input v-model="form.remark" placeholder="摘要" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="info" @click="save(false)">保存关闭</el-button>
            <el-button type="success" @click="save(true)">保存新增</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div>
      <add-supplier :item="supplierItem" @success="(id)=>{this.form.companyId=id}"></add-supplier>
      <add-client :item="clientItem" @success="(id)=>{this.form.companyId=id}"></add-client>
    </div>
  </div>
</template>

<script>
import BaseSelect from "@/components/BaseSelect.vue";

import AddSupplier from "@/views/supplier/Item";
import AddClient from "@/views/client/Item";
export default {
  props: ["item"],
  components: {
    BaseSelect,
    AddSupplier,
    AddClient
  },
  data() {
    return {
      visible: false,
      form: {},
      rules: {
        billType: [this.$g.required],
        companyId: [this.$g.required],
        amount: [this.$g.notZero]
      },

      supplierItem: {},
      clientItem: {}
    };
  },
  watch: {
    item: {
      handler: function(value) {
        this.initForm();
        this.visible = true;
      },
      deep: true
    }
  },
  methods: {
    initForm() {
      this.form = {
        billType: 1,
        companyId: "",
        amount: 0,
        remark: ""
      };
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
    typeChange() {
      this.form.companyId = "";
    },
    save(isContinue) {
      this.$refs["form"].validate(valid => {
        if (!valid) {
          return;
        }
        let bill = { ...this.form };
        let loading = this.$loading({
          lock: true,
          text: "保存中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        this.$post("CompanyInit/Add", bill)
          .then(r => {
            this.$message.success("操作成功！");
            this.$emit("success");
            if (isContinue) {
              this.initForm();
            } else {
              this.visible = false;
            }
          })
          .finally(() => {
            loading.close();
          });
      });
    }
  }
};
</script>

<style>
</style>